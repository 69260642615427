var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scoped_cover_div"},[_c('div',{staticClass:"jobs_table sp"},[_c('div',{},[_c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('section',[_c('div',{staticClass:"form_section1"},[_c('table',[_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"name","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Select User\n                    "),_c('span',[_vm._v("*")])]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.service.user_id),expression:"service.user_id"}],class:errors[0] ? 'warning-border':'',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.service, "user_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Select User")]),_vm._l((_vm.users),function(user,i){return _c('option',{key:'users'+i,domProps:{"value":user.id}},[_vm._v(_vm._s(user.name))])})],2)])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"name","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                   Select Carrier \n                  ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.service.provider_id),expression:"service.provider_id"}],class:errors[0] ? 'warning-border':'',on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.service, "provider_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.fetchServices]}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Select Carrier ")]),_vm._l((_vm.carriers),function(carrier,i){return _c('option',{key:'carriers'+i,domProps:{"value":carrier.id}},[_vm._v(_vm._s(carrier.name))])})],2)])]}}],null,true)})],1)]),_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"name","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                   Select Service Name\n                  ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.service.service_id),expression:"service.service_id"}],class:errors[0] ? 'warning-border':'',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.service, "service_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Select Service Name")]),_vm._l((_vm.services),function(service,i){return _c('option',{key:'services'+i,domProps:{"value":service.id}},[_vm._v(_vm._s(service.name))])})],2)])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"budget_amount","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Budget Amount\n                     \n                  ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.service.budget_amount),expression:"service.budget_amount"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Code Character Limit 30","maxlength":_vm.max},domProps:{"value":(_vm.service.budget_amount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.service, "budget_amount", $event.target.value)}}})])]}}],null,true)})],1)]),_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"approval_required_threshold","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Approval Required Threshold\n                    \n                  ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.service.approval_required_threshold),expression:"service.approval_required_threshold"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Code Character Limit 30","maxlength":_vm.max},domProps:{"value":(_vm.service.approval_required_threshold)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.service, "approval_required_threshold", $event.target.value)}}})])]}}],null,true)})],1)])])])])]}}])}),_c('section',{staticClass:"centered"},[_c('a',{staticClass:"link_bt bt_save",on:{"click":_vm.submit}},[_vm._v("Save")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }