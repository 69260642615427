<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp">
      <div class>
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <section>
          <div class="form_section1">
            <table>
              <tr>
                <td>
                  <ValidationProvider name="name" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Select User
                      <span>*</span>
                    </label>
                    <select v-model="service.user_id" :class="errors[0] ? 'warning-border':''">
                      <option value="" disabled>Select User</option>
                      <option v-for="(user,i) in users" :key="'users'+i" :value="user.id">{{user.name}}</option>
                    </select>
                  </div>
                  </ValidationProvider>
                </td>
                       <td>
                  <ValidationProvider name="name"  v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                     Select Carrier 
                    </label>
                    <select v-model="service.provider_id"  @change="fetchServices"  :class="errors[0] ? 'warning-border':''">
                      <option value="" disabled>Select Carrier </option>
                      <option v-for="(carrier,i) in carriers" :key="'carriers'+i" :value="carrier.id">{{carrier.name}}</option>
                    </select>
                  </div>
                  </ValidationProvider>
                </td>
            </tr>
            <tr>
                  <td>
                  <ValidationProvider name="name"  v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                     Select Service Name
                    </label>
                    <select v-model="service.service_id" :class="errors[0] ? 'warning-border':''">
                      <option value="" disabled>Select Service Name</option>
                      <option v-for="(service,i) in services" :key="'services'+i" :value="service.id">{{service.name}}</option>
                    </select>
                  </div>
                  </ValidationProvider>
                </td>
               
              

                <td>
                  <ValidationProvider name="budget_amount" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Budget Amount
                       
                    </label>
                    <input type="text" placeholder="Code Character Limit 30"  :class="errors[0] ? 'warning-border':''"  v-model="service.budget_amount" :maxlength="max"/>
                  </div>
                  </ValidationProvider>
                </td>
                
            
             </tr><tr>
                <td>
                  <ValidationProvider name="approval_required_threshold"  v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Approval Required Threshold
                      
                    </label>
                    <input type="text" placeholder="Code Character Limit 30"  :class="errors[0] ? 'warning-border':''"  v-model="service.approval_required_threshold" :maxlength="max"/>
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
          
            </table>
          </div>
        </section>
      </ValidationObserver>

        <section class="centered">
          <a class="link_bt bt_save" @click="submit">Save</a>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CarrierServices",
  data(){
    return {
      max:30,
      carriers:[],
      services:[],
      users:[],
      service:{
        budget_amount : "",
        provider_id:"",
        service_id:"",
        user_id:"",
        approval_required_threshold:""
      }
    }
  },
  created() {
    this.fetchCarriers();
    this.fetchUsers();
    
  },
  methods: {
    fetchCarriers(){
      this.axios.get("/api/provider/")
      .then(response => {
          this.carriers = response.data.providers;
      })
      .catch(error =>{
          this.toast.error();
      });
    },

    // to get carrier service
    fetchServices() {
      if(this.service.provider_id){
      this.axios.get("/api/provider/carrier/"+this.service.provider_id)
        .then(response => {
          this.services = response.data.provider.servicenames;
           if(this.services.length > 0){
              this.service.service_id = this.services[0].id;
            }
          })
       
        .catch(error => {
          console.log(error);
        });
      }
    },

   fetchUsers(){
      this.user_id = '';
      this.axios.get("/api/user/all")
      .then(response => {
          this.users = response.data.users;
           if(this.auth().user_type=='user')
           {
           this.service.user_id = this.users[0].id
           }
      })
      .catch(error =>{
          console.log(error);
      });
    },
    submit(){
      this.$refs.observer.validate();
      this.axios.post("/api/budget",this.service)
        .then(response => {
          this.toast.success(response.data.msg);
          this.$emit('redirect');
        })
        .catch(error =>{
          console.log(error);
        });
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}
.jobs_table.sp table {
  width: 100%;
}
.jobs_table.sp table,
.jobs_table.sp table tr td {
  border: 0;
  padding: 0 0 20px 0;
  vertical-align: top;
}
.jobs_table.sp table tr td {
  width: 33%;
}
.user_profile_upload {
  display: block;
  border: 0;
  height: 40px;
  width: 200px;
  background: #004a7c;
  color: #fff;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
}
.user_profile_upload input {
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.user_profile_upload span {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  color: #fff;
  font-weight: normal;
}
</style>