import { render, staticRenderFns } from "./UserServiceBudget_Add.vue?vue&type=template&id=6883c034&scoped=true&"
import script from "./UserServiceBudget_Add.vue?vue&type=script&lang=js&"
export * from "./UserServiceBudget_Add.vue?vue&type=script&lang=js&"
import style0 from "./UserServiceBudget_Add.vue?vue&type=style&index=0&id=6883c034&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6883c034",
  null
  
)

export default component.exports