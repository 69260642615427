<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp2">
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <table>
          <tr>
            <th style="width: 20%;">User Name</th>
            <th style="width: 20%;">Carrier </th>
             <th style="width: 20%;">Service Name</th>
            <th style="width: 20%;">Budget Amount</th>
            <th style="width: 20%;">Approval Required Threshold</th>

            <th>Action</th>
          </tr>
          <tr v-for="(budget,i) in serviceBudgets" :key="i">
            <td>
              <ValidationProvider name="user" rules="required" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <select v-model="budget.user_id" :disabled="!budget.is_edit_mode" :class="errors[0] ? 'warning-border':''">
                  <option value="" disabled>Select User</option>
                   <option
                    v-for="(user,i) in users"
                    :key="'users'+i"
                    :value="user.id"
                  >{{user.name}}</option>
                  
                </select>
              </div>
              </ValidationProvider>
            </td>
             <td>
             <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim> 
              <div class="label_and_element_wrapper"> 
                 <select v-model="budget.provider_id"   @change="fetchServices(budget.provider_id)" :disabled="!budget.is_edit_mode" >
                  <option value="" disabled>Select Carrier</option>
                  <option
                    v-for="(carrier,i) in carriers"
                    :key="'carriers'+i"
                    :value="carrier.id"
                  >{{carrier.name}}</option>
                </select>
              </div>
             
              </ValidationProvider>
            </td>
                <td>
             <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                 <select v-model="budget.service_id" :disabled="!budget.is_edit_mode" v-if="budget.is_edit_mode">
                  <option value="" disabled >Select Service Name</option>
                  <option
                    v-for="(service,i) in services"
                    :key="'services'+i"
                    :value="service.id"
                  >{{service.name}}</option>
                </select>

                <input  v-model="budget.service_name"   type="text" placeholder=" " :maxlength="max" disabled="!budget.is_edit_mode" v-if="!budget.is_edit_mode"  />
              </div>
              </ValidationProvider>
            </td> 

         <!--     <td>
             <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                 <select v-model="budget.service_id" :disabled="!budget.is_edit_mode" :class="errors[0] ? 'warning-border':''">
                  <option value>Select Service Name</option>
                  <option
                    v-for="(service,i) in services[i]"
                    :key="'services'+i"
                    :value="service.id"
                  >{{service.name}}</option>
                </select>
              </div>
              </ValidationProvider>
            </td> -->


            <td>
             <ValidationProvider name="code" rules="required|max:30" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <input  v-model="budget.budget_amount" 
                  type="text"
                  placeholder=" " :maxlength="max"
                  :class="errors[0] ? 'warning-border':''"
                 
                  :disabled="!budget.is_edit_mode"
                />
              </div>
              </ValidationProvider>
            </td>
            <td>
             <ValidationProvider name="type" rules="required" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                 <input v-model="budget.approval_required_threshold"
                  type="text"
                  placeholder=" " :maxlength="max"
                  :class="errors[0] ? 'warning-border':''"
                 
                  :disabled="!budget.is_edit_mode"
                />
              </div>
              </ValidationProvider>
            </td>

            <td class>
              <a class="table_action_btn" title="DELETE" @click="deleteService(budget.id)">DELETE</a>
              <a
                class="table_action_btn"
                title="CANCEL"
                v-if="budget.is_edit_mode"
                @click="cancelService()"
              >CANCEL</a>
              <a
                class="table_action_btn"
                title="UPDATE"
                v-if="budget.is_edit_mode"
                @click="updateService(budget)"
              >UPDATE</a>
              <a
                class="table_action_btn"
                title="EDIT"
                v-if="!budget.is_edit_mode"
                @click="budget.is_edit_mode=true; fetchServices(budget.provider_id)"
              >EDIT</a>
            </td>
          </tr>
        </table>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
export default {
  name: "CarrierServices",
  components: {},
  data() {
    return {
      max:30,
       
      carriers: [],
      serviceBudgets:[],
      services:[],
      budget:0
    };
  },
  created() {
    
    this.fetchCarriers();
    this.fetchUsers();
    this.fetchUserServiceBudget();
   

  },
  methods: {
    fetchCarriers() {
      this.axios
        .get("/api/provider/")
        .then(response => {
          this.carriers = response.data.providers;
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchUserServiceBudget() {
      this.axios
        .get("/api/budget")
        .then(response => {
          this.serviceBudgets = response.data.services.map(element => {
            element.is_edit_mode = false;            
            element.service_name = element.services.name;
            element.user_id = element.user_id;
            // if (element.provider_id) {
            //   element.service_id = element.service_id;
            // } else {
            //   element.provider_id = element.provider[0].id;
            // }
             // this.services = response.data.services.map(element1 => {
             //  return {
             //    id:element1.id,
             //    name:element1.name,
             //   // checked: good.modes.find(goodelement => goodelement.id == element1.id) ? element1.id : false
             //  }
            // });
            return element;
          });           
          
        })
        .catch(error => {
          console.log(error);
        });
    },

    fetchUsers(){
      this.user_id = '';
      this.axios.get("/api/user/all")
      .then(response => {
          this.users = response.data.users;
      })
      .catch(error =>{
          console.log(error);
      });
    },


    // // to get carrier service
    // fetchServices() {
    //   if(this.budget.provider_id){
    //   this.axios.get("/api/provider/carrier/"+this.budget.provider_id)
    //     .then(response => {
    //       this.services = response.data.provider.services;
    //        if(this.services.length > 0){
    //           this.budget.service_id = this.services[0].id;
    //         }
    //       })
       
    //     .catch(error => {
    //       console.log(error);
    //     });
    //   }
    // },

    // to get carrier service
    fetchServices(providerID) {      
     
      // if(this.budget.provider_id){
      this.axios.get("/api/provider/carrier/"+providerID)
        .then(response => {  
        
          this.services = response.data.provider.servicenames;          
            console.log(this.services);
           // if(this.services.length > 0){
           //    this.budget.service_id = this.services[0].id;
           //  }             
          })       
        .catch(error => {           
          console.log(error);
        });
     // }
    },
    updateService(data) {
     this.$refs.observer.validate();
      this.axios
        .put("/api/budget/" + data.id, data)
        .then(response => {
          this.toast.success(response.data.msg);
          this.fetchUserServiceBudget();
          
        })
        .catch(error => {
          console.log(error);
        });
    },
    deleteService(id) {
      if (confirm("Are you sure you want to delete this Carrier Services?")) {
        this.axios
          .delete("/api/budget/" + id)
          .then(response => {
            this.toast.success(response.data.msg);
            this.fetchUserServiceBudget();
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    cancelService(){
      this.fetchUserServiceBudget();
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}

.jobs_table.sp2 table tr td {
  padding: 5px 2px;
  vertical-align: top;
}
.jobs_table.sp2 table tr th {
  padding: 5px 10px;
  height: 50px;
  width: 25%;
}

.table_action_btn {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align-last: center;
  background: #06A5ED;
  color: #000;
  font-weight: 700;
  display: inline-block;
  margin: 2px;
}

.width_40px {
  width: 80px;
}
.righted {
  text-align: right;
  width: auto;
}
.label_and_element_wrapper input[type="text"] {
  padding: 5px 10px;
}
.user_profile_upload {
  display: block;
  border: 0;
  height: 40px;
  width: 150px;
  background: #004a7c;
  color: #fff;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
}
.user_profile_upload input {
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.user_profile_upload span {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  color: #fff;
  font-weight: normal;
}
.label_and_element_wrapper img {
  display: block;
  width: 150px;
  height: auto;
}
</style>